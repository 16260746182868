import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";

function Register() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const [affiliate, setAffiliate] = useState(null);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		} else {
			const urlParams = new URLSearchParams(window.location.search);
			const refParam = urlParams.get("ref");
			if (refParam) {
				setAffiliate(refParam);
			}
			if (localStorage.getItem("affiliate")) {
				setAffiliate(localStorage.getItem("affiliate"));
			}
		}
	}, []);
	const onSubmit = (data) => {
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Username yêu cầu ít nhất 6 kí tự",
			});
		}

		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Password yêu cầu ít nhất 6 kí tự",
			});
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: "Nhập lại password",
			});
		}

		if (data.code.length < 1) {
			setError("code", {
				type: "minLength",
				message: "Nhập mã giới thiệu",
			});
		}

		if (
			data.password.length < 6 ||
			data.username.length < 6 ||
			data.code.length < 1 ||
			data.password !== data.ippassword
		) {
			return;
		}
		// if (data.code === "") {
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
				username: data.username,
				password: data.password,
				code: data?.code,
				// code: "admin",
				sdt: data.sdt,
			})
			.then((res) => {
				swal({
					title: "Thông báo",
					text: "Đăng ký thành công",
					icon: "success",
					buttons: "OK",
				}).then(() => navigate("/login"));
			})
			.catch((err) => {
				setErr(
					err.response.data.message
						? err.response.data.message
						: "Đã xảy ra lỗi!"
				);
			});
		// } else if (data.code) {
		// 	axios
		// 		.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
		// 			username: data.username,
		// 			password: data.password,
		// 			code: data.code,
		// 			sdt: data.sdt,
		// 		})
		// 		.then((res) => {
		// 			swal({
		// 				title: "Thông báo",
		// 				text: "Đăng ký thành công",
		// 				icon: "success",
		// 				buttons: "OK",
		// 			}).then(() => navigate("/login"));
		// 		})
		// 		.catch((err) => {
		// 			setErr(
		// 				err.response.data.message
		// 					? err.response.data.message
		// 					: "Đã xảy ra lỗi!"
		// 			);
		// 		});
		// }
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [showPassword1, setShowPassword1] = useState(false);
	const toggleShowPassword1 = () => {
		setShowPassword1(!showPassword1);
	};
	return (
		<>
			<div className="login">
				<Link to="/cskh">
					<div style={{ color: '#087c95', position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}>
						<HeadsetMicOutlinedIcon style={{ width: '30px', height: '38px' }} />
					</div>
				</Link>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					{/*<img src={require("../../img/vietlott3.png")} alt="Logo" className="logologin"/>*/}
					<h1>Đăng Ký</h1>
					<div className="inputs">
						<div className="input">
							<input
								type="text"
								{...register("username", { required: true })}
								className="ip-lg"
								placeholder="Tên đăng nhập"
							/>
							{errors.username ? <p>{errors.username.message}</p> : null}
						</div>
						<div className="input">
							<input
								type={showPassword ? "text" : "password"}
								className="ip-lg"
								{...register("password", { required: true })}
								placeholder="Mật khẩu"
							/>
							<div onClick={toggleShowPassword}>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</div>
							{errors.password ? <p>{errors.password.message}</p> : null}
						</div>
						<div className="input">
							<input
								type={showPassword1 ? "text" : "password"}
								className="ip-lg"
								{...register("ippassword", { required: true })}
								placeholder="Nhập lại mật khẩu"
							/>
							<div onClick={toggleShowPassword1}>
								{showPassword1 ? <Visibility /> : <VisibilityOff />}
							</div>
							{errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
						</div>
						<div className="input">
							<input
								defaultValue={affiliate}
								type="code"
								className="ip-lg"
								{...register("code", { required: true })}
								placeholder="Nhập mã giới thiệu"
							/>
							{errors.code ? <p>{errors.code.message}</p> : null}
						</div>
						<div className="input">
							<input
								pattern="(\+84|0)\d{9,10}"
								type="sdt"
								className="ip-lg"
								{...register("sdt", { required: true })}
								placeholder="Nhập số điện thoại"
							/>
							{err ? <p>{err}</p> : null}
						</div>
					</div>
					<button type="submit" className="btn-lg">
						Đăng ký
					</button>
					<p className="p-lg">
						Đã có tài khoản?{" "}
						<Link className="a-lg" to="/login">
							Đăng nhập
						</Link>
					</p>
				</form>
			</div>
		</>
	);
}
export default Register;
